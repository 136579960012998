.loading-center {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.activayionpage-text {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.activayionpage-links {
    margin-top: 10px;
}

