/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap');
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.station-body-button, .pagination-btn, .counter-btn, .tariff-page-input-button, .users-saveBtn, .stations-modal-dell-btn,
.pass-fog-btn {
  color: #fff;
  position: relative;
  display: inline-block;
  line-height: 1;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  transition: all 0.4s ease;
  text-transform: uppercase;
  background-color: #41a350;
  border-style: none;
}

.grecaptcha-badge {
  display: none !important;
}

ul.contract li {
  list-style-type: circle !important;
}

#tariffsAndRules img {
  max-width: 100%;
}
