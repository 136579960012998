.statistics-block {
  margin-top: 110px;
  min-height: calc(100vh - 348px);
}

.dat-input-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-data {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.informer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.informer-box {
  margin: 25px;
  width: 30%;
  min-width: 200px;
  border-radius: 0.3rem;
  box-shadow: 0 0 3px 0 #9a9a9a;
}

.title {
  color: #fff;
  padding: 1rem 0;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  border-radius: 0.3rem 0.3rem 0 0;
}

.value {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  border-radius: 0 0 0.3rem 0.3rem;
}

.inf-value {
  font-size: 1.8rem;
  font-weight: 400;
}

.char-body {
  overflow-y: hidden;
  overflow-x: auto;
}

.statistics-table {
}

.table-head-horizontal {
  padding: 7px;
  background-color: #41a34d;
  color: #fff;
}

.table-head-item {
  padding: 10px;
}

.statistics-pages {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-page-num {
  border: none;
  background-color: unset;
}

.picked {
  border-radius: 3px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: gray !important;
}

.loader-body {
  display: flex;
  justify-content: center;
  min-height: 35vh;
  align-items: center;
}

.loader-body-img {
  height: 200px;
}

.no-data-block {
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1536px) {
  .statistics-block {
    margin-top: 82px;
    min-height: calc(100vh - 320px);
  }
}


@media (max-width: 768px) {
  .informer-box {
    margin: 5px;
    width: 25%;
    min-width: 164px;
  }

  .title {
    font-size: 16px;
  }

  .inf-value {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .table-head {
    display: none;
  }
  .table-body-block {
    display: block;
  }

  .table-body-item {
    margin: 15px;
    display: block;
    text-align: right;
    border-bottom: 1px solid #e8e9eb;
    padding: 0 5px;
  }

  .table-body-item:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
  }
}

@media (max-width: 540px) {
  .informer-box {
    margin: 5px;
    width: 25%;
    min-width: 164px;
  }
  .statistics-table {
    margin: 5px;
  }
  .table-body-item {
    margin: 7px;
  }

  .title {
    font-size: 16px;
  }

  .inf-value {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .stations-table {
    margin: 0 0 40px 0;
}
}

@media (max-width: 420px) {
  .informer {
    display: unset;
    justify-content: center;
    align-items: center;
  }
  .informer-box {
    margin: 15px;
    width: unset;
    min-width: 164px;
  }

  .title {
    font-size: 16px;
  }

  .inf-value {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
