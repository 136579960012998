.App {
  text-align: center;
  position: relative;
  height: 100%;
}

.leaflet-container a.leaflet-popup-close-button{
  font-size: 32px;
  padding-top: 6px;
  width: 26px;
  height: 20px;
}

.dateContainer{
  display: flex;
  max-width: 400px;
  height: 50px;
  align-items: center;
  padding: 10px 0 6px;
}

.dateContainer__date{
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateContainer__date:last-child{
  margin-left: 20px;
}

.dateContainer__date input{
  text-align: center;
}

@media (max-width: 768px) {
  .dateContainer {
    flex-direction: column;
    height: auto;
  }
  .dateContainer__date:last-child{
    margin-left: 0px;
    margin-top: 5px;
  }
}