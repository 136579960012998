.map-window {
  height: 100%;
  display: grid;
  width: 98, 7%;
  grid-template-columns: 6fr 1fr;
  overflow: hidden;
  padding: 120px 10px 10px 10px;
  background-color: #d6d6d6;
}
.map-body {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  height: 87vh;
  width: 100%;
  touch-action: none;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 500 !important;
}
.leaflet-container {
  height: 100%;
  width: 100%;
}
.easy-button-button {
  padding: 2.6px;
}

.charge-status {
  background: red;
  padding: 10px;
  text-align: center;
  margin: 0px 0 15px 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  cursor: pointer;
}

.charge-status-modal {
  background: red;
  padding: 10px;
  text-align: center;
  margin: 0px 0 15px 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  cursor: pointer;
}

.leaflet-tooltip-right:before {
  left: unset;
  margin-left: unset;
  border-right-color: unset;
}

.leaflet-tooltip {
  background-color: unset;
  border: unset;
  border-radius: unset;
  color: unset;
  box-shadow: unset;
  padding: unset;
  display: flex;
  text-align: center;
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border: 4px rgb(34, 33, 33) solid;
  border-radius: 50%;
  background: #41a350;
  align-items: center;
  justify-content: center;
}
.leaflet-tooltip-right {
  margin: 0px -22px;
}

.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

@media (max-width: 1536px) {
  .mo-block-body-text {
    padding: 2px 0;
  }
  .map-window {
    padding: 90px 8px 8px 8px;
  }
  .map-body {
    height: 90vh;
  }
}
@media (max-width: 768px) {
  .map-window {
    padding: 77px 7px 7px 7px;
  }
  .map-window {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 540px) {
  .map-body {
    height: 86vh;
  }

  .charge-status {
    position: fixed;
    z-index: 997;
    right: 7px;
    border: 1px solid gray;
    border-radius: 11px;
    top: 78px;
    margin: 0;
  }

  .map-window {
    padding: 76px 5px 5px 5px;
  }
}
