.tariff-page {
  margin: 110px  0 0;
  min-height: calc(100vh - 348px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.tariff-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2px;
  border-radius: 5px;
  margin: 60px 0 40px;
}

.tariff-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid black;
  position: relative;
  padding: 5px 25px 5px 5px;
}
.tariff-icon {
  display: flex;
  align-items: center;
  color: #434141;
  margin: 0 5px 0 0;
}

.tariff-item {
  margin: 5px 5px;
  font-size: 20px;
  display: flex;
}

.tariff-item-btn {
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
  border: none;
  background: none;
}

.tariff-page-input {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.tariff-page-input-item {
  margin: 5px;
  border: 2px solid #504d4d;
  font-size: 16px;
  padding: 12px 10px;
  width: 159px;
}

.tariff-page-input-item::placeholder {
  color: gray;
}

.tariff-page-input-buttons {
  display: flex;
  justify-content: center;
}

.tariff-add-btn {
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 10px;
  color: #fff;
  position: absolute;
  top: 10px;
  display: inline-block;
  line-height: 1;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  min-width: 100px;
  transition: all 0.4s ease;
  text-transform: uppercase;
  background-color: #41a350;
  border-style: none;
}

.tariff-page-input-button {
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  padding: 8px 12px;
}

.tariff-page-pagination {
  position: absolute;
  bottom: 10px;
}

.tariffs-modal {
  position: fixed;
  z-index: 2;
  top: -75px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.438);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tariffs-modal-box {
  display: flex;
  align-items: center;
}

.tariffs-modal-body {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  position: relative;
  z-index: 4;
}

@media (max-width: 1536px) {
  .tariff-page {
    margin: 82px 0 0;
    min-height: calc(100vh - 320px);
  }
}
@media (max-width: 1024px) {
  .tariff-page {
    margin: 82px 0 0;
    min-height: calc(100vh - 320px);
  }
  .tariff-body {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 840px) {
  .tariff-page {
    margin: 82px 0 0;
    min-height: calc(100vh - 402px);
  }
  .tariff-body {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 640px) {
  .tariff-body {
    grid-template-columns: 1fr 1fr;
  }
  .tariffs-modal {
    top: 0;
  }
}

@media (max-width: 480px) {
  .tariffs-modal-body {
    padding: 5px;
    margin: unset;
  }
}

@media (max-width: 440px) {
  .tariff-icon {
    margin: 0 2px 0 0;
  }
  .tariff-block {
    padding: 1px 13px 1px 3px;
  }
  .tariff-item {
    margin: 5px 2px;
    font-size: 17px;
    display: flex;
  }
  .tariff-item-btn {
    position: absolute;
    right: 0;
    top: 6px;
    cursor: pointer;
    border: none;
    background: none;
  }
}
