@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.userPage {
  margin: 110px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 348px)
}

.rotation-icon {
  animation: rotation 3s infinite linear;
}

.update-money {
  animation: rotation 1s infinite linear;
}
.userPage {
  margin-top: 110px;
}

.user-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.user-row {
  display: flex;
  padding: 10px;
  margin: 10px;
}

.user-point {
  display: flex;
  padding: 10px;
  align-items: baseline;
}

.user-tittle {
  font-size: 24px;
  font-weight: 600;
  padding: 0 10px;
}

.user-text {
  font-size: 18px;
  text-align: center;
}

.user-text svg{
  margin: 10px 0 0 10px;
}

.email-confirmation {
  margin: 0 10px;
  cursor: pointer;
}

.logout-btn {
  position: absolute !important;
  top: 5px;
  right: 25px;
  background-color: #ff3549 !important;
}

.afterRegBlock p{
  color: rgb(45, 45, 250);
  cursor: pointer;
}

.afterRegBlock p:hover{
  color: rgb(6, 6, 253);
}

.change-input-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.change-input {
  font-size: 18px;
  padding: 10px;
  margin: 5px;
  width: 70%;
}

.change-input-block-btn {
  display: flex;
  justify-content: center;
}

.change-input-btn {
  border: none;
  background-color: unset;
  cursor: pointer;
  transition: .3s;
  width: 37px;
}

.change-input-btn:hover {
 color: rgb(71, 70, 70);
 transition: .3s;
}

.change-input::placeholder {
  color: gray;
}

@media (max-width: 1536px) {
  .userPage {
    margin: 82px 0 0 0;
    min-height: calc(100vh - 320px)
  }
}

@media (max-width: 999px) {
  .user-body {
    align-items: unset;
  }
  .user-row {
    flex-direction: column;
    padding: unset;
    margin: unset;
    text-align: start;
  }
  .userPage {
    padding-top: 60px;
    height: 100%;
    margin: 12px 0;
  }
}
@media (max-width: 999px) {
  .logout-btn {
    top: 76px;
  }
  .userPage {
    margin: 0;
  }
}

@media (max-width: 360px) {
  .logout-btn {
    right: 5px;
  }
}
