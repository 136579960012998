.mo-block-body-text {
  display: flex;
  align-items: center;
}

.mo-block-image {
  height: 28px;
}

.map-options-blocks {
  height: 87vh;
  padding: 0 0 0 15px;
  display: flex;
  flex-direction: column;
}

.mo-block {
  margin-bottom: 14px;
  padding: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.74);
}

.mo-block-header {
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  padding: 0 0 10px 0;
}
.mo-block-body-text {
  padding: 5px 0;
  text-align: left;
}

.mo-block-inbut {
  cursor: pointer;
}

.mo-btn-img {
  height: 45px;
}

.mo-btn {
  height: 70px;
  display: none;
  top: 250px;
  right: 0px;
  border: unset;
  background: unset;
}

@media (max-width: 1367px) {
  .mo-block {
    margin-bottom: 7px;
  }

  .mo-block-header {
    padding: 0 0 5px 10px;
    font-size: 20px;
  }

  .mo-block-image {
    height: 26px;
  }

  .mo-block-body-text {
    padding: 2px 0;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .mo-show {
    display: none;
  }
  .map-options-blocks {
    height: unset;
    padding: 5px 0 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 71px;
    right: 5px;
    width: 230px;
    font-size: 20px;
    z-index: 998;
  }
  .mo-btn {
    height: 70px;
    display: block;
  }
}

@media (max-width: 540px) {
  .mo-hide-show {
    width: 200px;
  }

  .mo-block-inbut {
    height: 30px;
    width: 30px;
  }
}

@media (max-width: 320px) {
  .mo-block {
    margin-bottom: 5px;
    padding: 4px;
  }
  .mo-block-header {
    font-size: 18px;
  }
  .mo-block-image {
    height: 24px;
  }
  .mo-btn {
    top: 216px;
  }
}
@media (max-width: 320px) {
  .map-options-blocks {
    font-size: 16px;
  }
}
