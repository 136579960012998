@keyframes fadeInBottom {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  0% {
    transform: translateX(-120px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0);
  }
}

.video_block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 652px;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.charge_car_box{
  display: none;
}

.intro_image {
  position: absolute;
  /* filter: blur(10px); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: visibility 0ms ease 400ms;
}

.bung-block {
  height: 652px;
  position: relative;
  z-index: -5;
}

.elementor-background {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.under_video_blocks {
  animation: fadeInBottom 1s;
  height: 652px;
  display: flex;
  align-items: flex-end;
  align-self: flex-start;
  width: 100%;
  text-align: center;
  max-width: 1905px;
}

@media (min-width: 1905px) {

  .under_video_blocks {
    margin-left: calc(50% - 952px);
  }

}

.under_video_block {
  font-weight: 300;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  display: grid;
  grid-template-rows: 2fr 1fr 3fr;
  align-items: center;
  width: 33.33333%;
  z-index: 0;
  height: 30%;
  /* border: 1px solid #fff; */
  position: relative;
  transition: 0.4s;
}

.under_video_block:hover {
  background-color: rgba(0, 0, 0, 0.521);
  transition: 0.8s;
}
.uvb-icon {
  font-size: 40px;
  color: #a31642;
}

.uvb-tittle {
  font-size: 32px;
  font-weight: 600;
}

.uvb-text {
  padding: 0 calc(17% - 25px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.uvb-strong-text {
  font-weight: 600;
}

/* configuration-block */

.configuration-container {
  padding: 0 calc(50% - 700px);
}

.configuration-block {
  display: grid;
  min-height: 460px;
  padding: 40px 0;
  grid-template-columns: 4fr 9fr;
}

.configuration-tittle {
  padding-right: 25px;
  border-right: 1px solid #dddddd;
  display: flex;
  max-width: 360px;
  align-items: center;
}

.ct-body {
  animation: fadeInLeft 1s;
  flex-direction: column;
}

.ct-header {
  text-align: right;
  padding-left: 45px;
  font-size: 28px;
  font-weight: 700;
}
.ct-line {
  margin-top: 25px;
  height: 4px;
  display: flex;
  float: right;
}

.ct-dot {
  width: 4px;
  margin: 0 4px;
  border-radius: 2px;
  background-color: #a31642;
}

.ct-dot_2 {
  width: 45px;
  margin: 0 4px;
  border-radius: 2px;
  background-color: #a31642;
}

.ct-text {
  margin-top: 50px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
}

.configuration-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cb-body {
  display: flex;
  flex-direction: row;
  vertical-align: top;
  width: 100%;
}

.cb-block {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cb-block-body {
  padding: 25px;
  flex-direction: column;
}

.cb-block-img {
  text-align: center;
  transition: 0.8s;
}

.cb-block-img:hover {
  transform: scale(1.2);
  transition: 0.8s;
}

.cb-block-text {
  margin-top: 50px;
  font-size: 18px;
}

/* model */

.model-container {
  padding: 40px calc(50% - 700px);
  min-height: 350px;
  background-color: #f7f7f7;
}

.model-block {
  display: grid;
  height: 100%;
  grid-template-columns: 4fr 7fr;
}

.model-tittle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
}

.mb-block-img {
  height: 350px;
}

.model-body {
  display: flex;
  padding: 0 40px;
  align-items: center;
}

.mb-body {
  font-size: 20px;
  font-weight: 600;
}

.mc-img {
  height: 90%;
  transition: 1s;
}

.mc-img:hover {
  transform: scale(1.2);
  transition: 1s;
}

.mt-body {
  animation: fadeInRight 1s;
  padding: 0 25px;
}

.mt-line {
  margin-top: 25px;
  height: 4px;
  display: flex;
  float: left;
}
.mt-dot {
  width: 4px;
  margin: 0 4px;
  border-radius: 2px;
  background-color: #a31642;
}

.mt-dot-2 {
  width: 45px;
  margin: 0 4px;
  border-radius: 2px;
  background-color: #a31642;
}

.mt-button {
  cursor: pointer;
  font-size: 15px;
  padding: 15px 30px;
  display: inline-block;
  color: #fff;
  position: relative;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-transform: uppercase;
  background-color: #a31642;
  border-style: none;
  border-radius: 0;
}

button:focus {
  outline: none;
}

.mt-button:hover {
  color: #ffffff;
  background-color: #525252;
}

/* advantages */

.advantages-container {
  /* max-width: 1140px; */
  padding: 40px calc(50% - 700px);
  min-height: 460px;
}

.advantages-block {
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 40px;
}

.at-tittle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}

.advantages-blocks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.ad-block {
  margin: 10px;
}

.ad-block-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ad-block-icon {
  margin: 15px;
  padding: 25px 0;
}

.mt-button-block {
  display: flex;
  justify-content: center;
}

.buy-btn {
  min-width: 250px;
}

/* business-request-container */

.business-request-container {
  padding: 40px calc(50% - 700px);
  height: 250px;
  background-color: #f7f7f7;
}

.business-request-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.business-request-block-head {
  font-size: 20px;
  font-weight: 600;
}

.business-request-block-head {
  padding: 0 10px 45px 10px;
  font-size: 24px;
  text-align: center;
}

.business-request-block-text {
  padding: 0 10px 45px 10px;
  font-size: 22px;
  text-align: center;
}

/* Mobile app */

.mobile-app-container {
  padding: 40px calc(50% - 700px);
  min-height: 360px;
}

.mobile-app-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mobile-app-block-head {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}

.mobile-app-block-text {
  max-width: calc(100% - 250px);
  padding: 0 10px;
  font-size: 18px;
  margin-bottom: 25px;
}

.mobile-app-icons {
  display: grid;
  padding: 40px 0;
  grid-template-columns: repeat(5, 1fr);
}

.mobile-app-icon-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
}

.mobile-app-icon {
  padding: 25px;
}
.mobile-app-icon-icon {
  height: 80%;
}

/* Slider */

.slider-container {
  /* max-width: 1140px; */
  padding: 40px calc(50% - 700px);
  min-height: 310px;
}

.slider-elementor {
  display: grid;
  height: 100%;
  grid-template-columns: 15fr 20fr;
  padding-bottom: 40px;
}

.slider-box {
  height: 260px;
  margin: 25px 25px 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.slider-text-box {
  border-left: 1px solid #dddddd;
  padding: 25px;
  display: flex;
  align-items: center;
}

.slider-text {
  font-size: 20px;
  text-align: left;
}

.slider-btn {
  display: flex;
  justify-content: center;
}

/* MAP-INFO */

.mapinfo-container {
  background-color: #f4f4f4;
  padding: 40px calc(50% - 700px);
  min-height: 100%;
}

.mapinfo-elementor {
  min-height: 160px;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 18px;
}
.mapinfo-text {
  border-right: 1px solid #dddddd;
  padding: 25px;
}

.mapinfo-box {
  cursor: pointer;
  font-size: 120px;
  padding: 0 50px;
  transition: 0.4s;
}
.mapinfo-box a {
  color: #a31642;
  transition: 0.4s;
}

.mapinfo-box:hover {
  transition: 0.4s ease-in-out;
  transform-origin: right;
}

.mapinfo-box a:hover {
  color: #525252;

  transition: 0.4s;
}

.carousel-image {
  cursor: pointer;
  height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gre-captcha-badge {
  visibility: hidden;
}

@media (max-width: 1140px) {
  .configuration-block {
    padding: 40px 25px;
  }
  .slider-box {
    margin: 0 25px;
  }
  .mapinfo-text {
    padding: 0 25px;
  }

  .request-text {
    padding: 0 25px;
  }

  .slider-elementor {
    grid-template-columns: 18fr 20fr;
  }
}

@media (max-width: 1024px) {
  .advantages-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .configuration-body {
    padding: 0 25px;
  }

  .ad-block-text {
    font-size: 18px;
  }

  .slider-text {
    font-size: 20px;
  }
  .mt-header {
    font-size: 24px;
  }
  .mt-text {
    font-size: 16px;
  }

  .ad-lll {
    border-left: none;
  }

  .ad-bbb {
    border-bottom: none;
  }

  .ad-rrr {
    border-right: 1px solid #dddddd;
  }
  .ad-ddd {
    border-bottom: 1px solid #dddddd;
  }
}

@media (max-width: 768px) {
  .nav-logo {
    height: 50px;
  }

  .under_video_block {
    width: 50%;
    padding: 35px 0;
    display: inline-block;
  }

  .under_video_blocks {
    height: 390px;
    flex-wrap: wrap;
  }

  .charge_car_box{
    display: block;
    z-index: 0;
    padding: 20px;
  }

  .charge_car_btn{
    padding: 10px 10px;
    position: relative;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    color: #FFF;
    transition: all .4s ease;
    text-transform: uppercase;
    background-color: #a31642b5;
    border-style: none;
    border-radius: 0;
    min-width: 230px;
    height: 4rem;
    font-size: 26px;
  }

  .charge_car_btn:hover {
    background: #a31643!important;
    box-shadow: 0 4px 17px rgba(0,0,0,0.2)!important;
}

  .under_video_block .uvb-tittle {
    font-size: 24px;
  }

  .under_video_block .uvb-text {
    font-size: 18px;
  }

  .nav-items {
    padding: 5px;
  }

  .configuration-tittle {
    max-width: none;
    padding-bottom: 25px;
  }

  .model-body {
    padding-bottom: 25px;
  }

  .mt-button {
    float: none;
  }

  .slider-elementor {
    grid-template-columns: none;
  }

  .configuration-block {
    grid-template-columns: none;
  }

  .model-block {
    grid-template-columns: none;
  }

  .mapinfo-elementor {
    font-size: 16px;
  }

  .advantages-block {
    grid-template-columns: none;
  }

  .mobile-app-block-text {
    max-width: unset;
  }
  .mobile-app-icons {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {

  .configuration-block {
    padding: 40px 0;
  }

  .configuration-tittle {
    padding-right: 0;
    border-right: none;
  }

  .under_video_block .uvb-tittle {
    font-size: 20px;
  }

  .under_video_block .uvb-text {
    font-size: 14px;
  }

  .charge_car_box{
    padding: 10px;
  }

  .charge_car_btn{
    font-size: 18px;
  }

  .ct-body {
    padding: 25px;
  }

  .cb-block-text {
    margin-top: 15px;
    font-size: 16px;
  }

  .mb-block {
    padding-bottom: 15px;
  }
  .mb-block-img {
    height: 240px;
  }

  .cb-block {
    width: 100%;
  }

  .ct-header {
    font-size: 24px;
  }

  .ct-text {
    font-size: 16px;
  }

  .under_video_blocks {
    padding: 15px 0;
    height: 350px;
  }

  .mapinfo-elementor {
    display: block;
    align-items: center;
    text-align: center;
  }
  .at-header {
    font-size: 24px;
  }
  .ad-block-text {
    font-size: 13px;
  }
  .mapinfo-box {
    padding: 15px 50px;
  }
  .slider-box {
    margin: 0 10px;
  }
  .mb-body {
    display: block;
  }
  .cb-body {
    display: block;
  }
  .ad-block-icon {
    font-size: 28px;
  }
  .slider-text {
    font-size: 18px;
  }
  .slider-text-box {
    padding: 10px;
  }
}

@media (max-width: 320px) {
  .advantages-blocks {
    padding: 15px;
  }

  .under_video_blocks {
    font-size: 14px;
  }
  .uvb-icon {
    font-size: 32px;
  }
}
