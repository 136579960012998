.navbar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  font-size: 18px;
  transition: 0.4s;
  background-color: rgba(15, 15, 15, 0.29);
}

.leaflet-top {
  z-index: 400;
}

.logout {
  color: #fff;
  font-size: 20px;
  margin: 0 12px 0 0;
}

.navbar-active {
  background-color: #136089;
  transition: 0.4s;
}

.nav-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.nav-logo {
  display: flex;
  align-items: center;
  height: 70px;
}

.logoimage {
  height: 100%;
}

.nav-menu {
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.219);
    border-radius: 10px; */
}

.menu-item-p {
  text-align: end;
}

.menu-item-ico {
  display: flex;
  align-items: center;
}
.menu-item-ico a{
  text-decoration: none;
  color: #fff;
}

.menu-item {
  position: relative;
  padding: 5px;
  margin: 2px;
  color: #fff;
  transition: 0.3s;
}

.menu-item:hover {
  color: #d3c3c8;
  transition: 0.3s;
}
.langmenu {
  height: 30px;
  width: 30px;
}

.item-border {
  border: 2px solid #fff;
  border-radius: 50%;
}
.item-border:hover {
  border: 2px solid #a31642;
  transition: 0.4s;
  color: #a31642;
}

.up-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  padding: 2px 10px;
  /* border: 1px solid black; */
  border-radius: 5px;
}
.up-btn:hover {
  background-color: rgb(227, 227, 228);
}

@media (max-width: 1536px) {
  .nav-items {
    padding: 6px;
  }
}

@media (min-width: 1280px) {
  .logoimage {
    width: 410px;
  }
}

@media (max-width: 1280px) {
  .logoimage {
    width: 300px;
  }
}

@media (max-width: 1024px) {
  .logoimage {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .nav-items {
    padding: 10px;
  }

  .nav-logo {
    height: 50px;
  }
  .menu-item-text, .menu-item-phone {
    display: none;
  }
}

@media (max-width: 500px) {
  .nav-logo {
    display: block;
  }
  .logoimage {
    width: auto;
  }
}

@media (max-width: 480px) {
  .menu-box {
    padding: 30px 0 !important;
  }
  .h-menu-item {
    padding: 10px;
  }
}
