.charge-body {
    margin: 170px auto;
}

.use-charge {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-between;
    padding-bottom: 25px;
}

.use-close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    border: unset;
    background: unset;
}

.charge-img {
    height: 75px;
}

.use-charge-centre {
    border: 30px solid green;
    border-radius: 50%;
    height: 20vh;
    width: 20vh;
    display: flex;
    flex-direction: column;
}

.use-charge-connector {
    margin: 35px  auto 0;
}

.use-charge-counter {
    margin: 2px auto;
    font-size: 28px;
    font-weight: 700;
}

.use-charge-right {
    text-align: end;
}
.station-opt {
    padding-bottom: 20px;
}

.use-btn-text {
    text-align: center;
}

.use-btn-calk {
    border: 1px solid rgb(194, 193, 193);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: rgb(240, 240, 240);
}

.use-btn {
    background-color: #FF3549;
}

.use-btn-block {
    display: flex;
    flex-direction: column;
}

.use-btn-text {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 10px;
}
.use-after-btn {
    padding-bottom: 10px;
    font-size: 24px;
}

.use-info {
    width: 100%;
}

.station-info {
    justify-items: center;
}

.use-charge-border {
    display: flex;
    justify-content: center;
}

@media (max-width: 1536px) { 
    .charge-body {
        margin: 100px auto;
    }
    .charge-img {
        height: 65px;
    }
    .use-charge-counter {
        font-size: 22px;
    }
}

@media (max-width: 1024px) { 
    .use-charge-centre {
        height: 14vh;
        width: 14vh;
    }
    .charge-img {
        height: 60px;
    }
    .use-charge-counter {
        font-size: 20px;
    }
    .use-charge-connector {
        margin: 30px auto 0;
    }
}
@media (max-width: 768px) { 
    .use-charge-centre {
        height: 16vh;
        width: 16vh;
    }
    .use-charge-counter {
        font-size: 24px;
    }

}
@media (max-width: 540px) { 
    .charge-img {
        height: 60px;
    }
    .use-charge-connector {
        margin: 30px auto 0;
    }
    .use-charge-counter {
        font-size: 22px;
    }

    .use-charge-centre {
        border: 25px solid green;
        height: 20vh;
        width: 20vh;
    }
}

@media (max-width: 450px) { 
    .use-charge-connector {
        margin: 20px  auto 0;
    }
    .use-charge {
        padding-bottom: 10px;
    }
    .use-btn-calk {
        padding-bottom: 10px;
    }
    .charge-body {
        padding: 35px 10px;
    }
    .charge-img {
        height: 45px;
    }
    .use-charge-centre {
        border: 20px solid green;
        height: 120px;
        width: 120px;
    }
    .use-charge-counter{
        font-size: 15px;
    }
    .charge-body {
        margin: 70px auto;
    }
}
