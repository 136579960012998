.moneyUp-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 373px);
    margin: 110px 0 25px;
}
.cash-img {
    height: 150px;
}
.pay-btn {
    margin: 10px!important;
}

.hidden-btn {
    display: none!important;
}

@media (max-width: 1536px) {
    .moneyUp-body {
        margin: 82px 0 25px;
        min-height: calc(100vh - 345px);
    }
}

@media (max-width: 540px) {
    .cash-img {
        height: 75px;
    }
    .pay-btn {
        margin: 5px;
    }
}
