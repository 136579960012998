.stations-page {
  margin: 110px 0 0;
  min-height: calc(100vh - 320px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.station-page-pagination {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.stations-table {
  border-top: 1px solid gray;
  margin: 0 0 40px 0
}

.stations-table-body-block {
  margin: 0 0 25px;
}

.stations-add-btn-top {
  display: none;
  width: 150px;
  cursor: pointer;
  margin: 0 0;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 15px;
  color: #fff;
  position: relative;
}

.station-page-num {
  border: unset;
  background: unset;
}
.stations-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.438);
  display: flex;
  justify-content: center;
  z-index: 998;
  overflow: scroll;
}

.stations-modal-map {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.438);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.stations-add-btn {
  border: unset;
  width: 40px;
  cursor: pointer;
  margin: 0 0;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  position: relative;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  transition: all 0.4s ease;
  text-transform: uppercase;
  background-color: #41a350;
}

.stations-add-btn:hover {
  color: rgb(78, 78, 78);
  transition: 0.3s;
}

.stations-modal-body {
  background-color: #fff;
  border-radius: 10px;
  margin: 120px 0 0;
  padding: 25px;
  position: relative;
  z-index: 4;
}

.stations-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: unset;
  cursor: pointer;
}

.stations-modal-close-btn:hover {
  color: gray;
}

.table-body-btn-body {
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: space-around;
}
.table-body-btn {
  border: none;
  background-color: unset;
  cursor: pointer;
  transition: 0.3s;
}
.table-body-btn:hover {
  color: gray;
  transition: 0.3s;
}

.stations-modal-input-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.stations-modal-input-head {
  font-size: 18px;
  font-weight: 600;
}

.stations-modal-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.438);
}

.stations-modal-input {
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px gray solid;
  margin: 5px;
}

.stations-modal-connectors {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  position: relative;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.74);
}

.stations-modal-connectors-head {
  padding: 0 0 5px;
}

.stations-modal-connectors-num {
  padding: 8px;
  letter-spacing: inherit;
  align-items: center;
  display: flex;
  font-size: larger;
  justify-content: center;
}

.stations-modal-type {
  padding: 6px;
  font-size: 16px;
  width: 110px;
}

.stations-modal-connectors-power {
  font-size: 17px;
  text-align: center;
  padding: 6px 12px 6px;
  width: 92px;
}

.stations-modal-tariff-id {
  width: 120px;
  padding: 6px;
  font-size: 16px;
}
.stations-modal-connectors-pairs {
  display: flex;
  justify-content: space-around;
}

.stations-modal-connectors-btn-body {
  display: flex;
  justify-content: center;
  margin: 20px 0 20px;
}

.stations-modal-connectors-add-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stations-modal-connectors-add {
  border: unset;
  background-color: unset;
  cursor: pointer;
  transition: 0.3s;
}

.stations-modal-connectors-add:hover {
  color: gray;
  transition: 0.3s;
}

.stations-modal-dell-text {
  margin: 15px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.stations-modal-dell {
  display: flex;
  justify-content: space-around;
}

.stations-modal-dell-btn {
  cursor: pointer;
  margin: 5px 5px;
  border-radius: 5px;
  font-size: 16px;
  padding: 8px 13px;
}

.map-window-add {
  width: 90%;
  background-color: #2525256b;
  border-radius: 5px;
}

.add-btn-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  z-index: 500;
  display: flex;
  justify-content: center;
}

.add-btn-btn, .stations-modal-connectors-btn, .stations-add-btn-top {
  line-height: 1em;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  transition: all 0.4s ease;
  text-transform: uppercase;
  background-color: #41a350;
  border-style: none;
}

.add-btn-btn, .stations-modal-connectors-btn {
  cursor: pointer;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 13px;
  color: #fff;
  position: relative;
  display: inline-block;
}

@media (max-width: 1536px) {
  .stations-page {
    margin: 82px 0 0;
    min-height: calc(100vh - 320px);
  }
  .stations-modal-body {
    margin: 90px 0 0;
  }
}

@media (max-width: 1093px) {
  .stations-page {
    margin: 82px 0 0;
    min-height: calc(100vh - 320px);
  }
  .stations-add-btn-top {
    margin: 10px 0;
  }

  .stations-table-head {
    display: none;
  }
  .stations-table-body-block {
    display: block;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.74);
    margin: 10px;
  }

  .stations-table-body-item {
    display: block;
    text-align: right;
    border-bottom: 1px solid #e8e9eb;
    padding: 10px 10px;
  }

  .stations-table-body-item:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
    padding: 0 0 0 10px;
  }

  .stations-add-btn-top {
    display: block;
  }
  .stations-table-head {
    display: none;
  }
  .table-body-btn-body {
    display: flex;
    height: unset;
    flex-direction: unset;
    justify-content: unset;
  }
  .table-body-btn-block {
    display: flex;
    justify-content: center;
  }
  .table-body-btn {
    margin: 0 50px;
  }
}
@media (max-width: 768px) {
  .stations-page {
    margin: 70px 0 0;
    min-height: calc(100vh - 402px);
  }
}
@media (max-width: 480px) {
  .stations-modal-input-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .stations-table-body-block {
      margin: 5px;
    }

  .stations-modal-connectors {
    margin: 5px 0 0 0;
    width: 310px;
  }

  .stations-modal-connectors-title {
    margin: 0 30px;
  }
  .stations-modal-connectors-power {
    font-size: 18px;
    padding: 6px 10px 6px;
    width: 94px;
  }
  .stations-modal {
    position: fixed;
    top: 0;
    left: 0;
    margin: 70px 0 0 0;
    right: 0;
    bottom: 0;
    height: calc(100vh - 60px);
    overflow: scroll;
  }

  .stations-modal-body {
    padding: 5px;
    margin: unset;
  }
  .stations-modal-input-block {
    margin: 25px 0 0 0;
  }

  .stations-modal-tariff-id {
    width: 120px;
  }
  .stations-mob-body {
    height: 100%;
  }
}
@media (max-width: 360px) {
  .stations-table-body-block {
    margin: unset;
  }
}
