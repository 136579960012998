@import "./materialize.min.css";

.registratin {
    width: 100%;
    margin: 110px 0 0; 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 348px);
}

.cont {
    min-height: 50vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.wrap-login {
    display: flex;
    flex-direction: row;
    text-align: center;
}
.login-form {
    background-color: rgba(255, 255, 255, 0.192);
    text-align: center;
    padding: 40px;
}

.login-form:focus {
outline: none;
}

.login-form-title {
position: relative;
padding: 20px;
font-size: 32px;
font-weight: 400;
text-transform: uppercase;
}

.login-form-tittle-btn {
position: absolute;
font-size: 42px!important;
top: 19px;
left: -11px;
}

.input-area {
    background-color: rgba(255, 255, 255, 0.856)!important;
    margin: 10px!important;
    height: 45px!important;
    padding: 10px!important;
    font-size: 16px!important;
    width: 320px;
}

.PhoneInputInput {
background-color: rgba(255, 255, 255, 0.856)!important;
margin: 10px!important;
height: 3rem!important;
padding: 10px!important;
font-size: 16px!important;
width: 20rem!important;

}

.input-area::placeholder{
color: rgba(0, 0, 0, 0.479)!important;
}


@keyframes pulse {
    0% {
    transform: scale(0);
    opacity: 0;
    }
    33% {
    transform: scale(1);
    opacity: 1;
    }
    100% {
    transform: scale(3);
    opacity: 0;
    }
}

.button-login {
    padding: 10px 10px;
    position: relative;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    color: #FFF;
    transition: all .4s ease;
    text-transform: uppercase;
    background-color: #41a350;
    border-style: none;
    border-radius: 0;
    min-width: 230px;
    margin: 10px 10px;
    height: 4rem;
    font-size: 28px;
}
.enemy-pc {
cursor: help;
display: flex;
justify-content: center;
}


    
.button-login:hover {
    background: #2e3030!important;
    box-shadow: 0 4px 17px rgba(0,0,0,0.2)!important;
}
.button-login:active {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
    transform: translate3d(0, 1px, 0);
}

.showpassword-btn-auth {
cursor: pointer;
top: 34px;
position: absolute;
right: 80px;
border: none;
background-color: unset;
border-radius: 50%;
}

.showpassword-btn-aut:hover { 
background-color: rgb(0, 0, 0);
color: #fff;
}

.btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.pulse {
position: relative;
}
.pulse:before, .pulse:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.4);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin: auto;
}
.pulse:before {
animation: pulse 1.5s infinite linear;
}
.pulse:after {
animation: pulse 2s .4s infinite linear;
}
.pulse:hover:before, .pulse:hover:after {
display: none;
}

.login-text-button {
border-radius: 35px;
background-color: rgba(255, 255, 255, 0.521);
}
@media (max-width: 1536px) {
    .registratin {
        width: 100%;
        margin: 82px 0 0; 
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: calc(100vh - 320px);
    }
}

@media (max-width: 539px) { 

.login-form{
    padding: 54px 0 0 0;
}
.login-form-title {
    padding: 13px;
    font-size: 22px;
}
.input-area {
    width: 270px;
}
.showpassword-btn-auth {
    right: 25px;
}  

}



