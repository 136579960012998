.popup-tittle {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
}

.popup-address {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0.4rem 0;
    text-align: center;
}
.popup-workTime {

    padding: 2px;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center !important;
    margin: 0.7rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-workTime > svg {
    position: relative;
    margin: 0 5px;
}

.popup-connector-type {

}

.popup-icon {
    position: relative;
}

.popup-connectors {
    display: flex;
    justify-content: center;
}

.popup-connector {
    display: grid;
    justify-content: space-between;
}
.popup-connector-type {
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
}

.popup-connector-power {
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
}

.popup-connector-status {
    text-align: center;
    align-items: center;
    margin: auto;
    padding: 3px;
    border-radius: 50%;
    height: 44px;
    width: 44px
    /* display: flex; */
}

.popup-connector-status-status {
    font-size: 32px;
    font-weight: 600;
}

.popup-btn {
    cursor: pointer;
    margin: 10px 0;
    font-size: 16px;
    padding: 10px 30px;
    display: inline-block;
    color: #FFF;
    position: relative;
    line-height: 1;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    transition: all .4s ease;
    text-transform: uppercase;
    background-color: #41A350;
    border-style: none;
    border-radius: 0;
}
.leaflet-popup-content-wrapper {
    text-align: center;
}
