.counter-body {
  display: flex;
  align-items: center;
}
.counter-input {
  padding: 8px;
  font-size: 20px;
  width: 100px;
  margin: 0 10px;
}

.counter-button-area {
  display: flex;
  flex-direction: column;
}

.counter-btn {
  cursor: pointer;
  margin: 3px 0;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
}
