/* footer */

.footer-container {
    /* max-width: 1140px; */
    padding: 40px calc(50% - 700px);
    min-height: 100%;
    background-color: #333;
}

.footer-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.footer-col {
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fc-icon {
    height: 75px;
}

.fc-item {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-align: left;
    margin: 10px 0;
    text-decoration: none;
}

.fc-item-text {
    color: #fff
}

.fc-icon {
    padding: 0 10px 0 0 ;
    color: #136089;
}

.footer-bar {
    background-color: rgb(20, 20, 20);
    min-height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
}

.fb-block {
    margin: auto;
}

.fb-text {
    color: #fff;
    text-align: center;
}

.fb-text a {
    color: #fff;
    text-decoration: none;
}

@media (max-width: 1024px) {
    .footer-col {
        padding: 0 20px;
    }
    .fc-title {
        font-size: 24px;
    }
    .fc-icon {
        height: 50px;
    }
}

@media (max-width: 768px) {
    .fc-title {
        font-size: 30px;
    }

    .fc-item {
        font-size: 18px;
    }

    .footer-cols {
        grid-template-columns: none;
    }

    .footer-col {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

}

@media (max-width: 480px) {
    .footer-col {
        padding: 0 0 0 15px;
    }

    .fb-text {
        font-size: 12px;
    }

    .fc-icon {
        height: 30px;
    }

}
