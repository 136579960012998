.dropMenu-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.dropMenu{
    position: absolute;
    display: grid;
    grid-gap: 5px;
    top: 45px;
    left: 1px;
    background-color: rgba(0, 0, 0, 0.445);
    border-radius: 8px;

}

.dropOption{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
}

.dropOption:hover {
    background-color:  rgba(187, 184, 184, 0.623);
    border-radius: 6px;
}

.dropOption-image {
    height: 30px;
}


