/*noinspection CssUnknownTarget*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@keyframes fade {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}

.station-prop {
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: "Montserrat", sans-serif;
}

.station-prob-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.288);
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.station-body {
  z-index: 1001;
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
  background-color: rgb(219, 218, 218);
  border-radius: 10px;
  padding: 25px;
  display: flex;
  align-items: center;
  max-width: 550px;
  margin: 70px auto;
  flex-direction: column;
}

.station-body-head {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.station-title {
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 20px;
}

.station-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-bottom: 20px;
}

.station-connector-bold {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
}

.station-connectors {
  width: 100%;
}

.station-connector {
  padding-bottom: 20px;
}

.input-checkbox {
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 0.1rem solid #41a350;
  background-color: #eeeeee;
  display: inline-block !important;
}

.input-checkbox:checked {
}

.station-connector-prop {
  margin: 0 0 0 20px;
}

.station-connector-tittle {
  color: #fff;
  /* background-color: #41a350; */
  border-radius: 7px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
}

.connector-img {
  width: 48px;
  margin: 0 10px;
}

.station-connector-img {
  display: flex;
  align-items: center;
}

.station-connector-picker {
  align-self: center;
}

.station-prob-close-btn {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 28px;
  color: black;
  transition: 0.4s;
}
.station-prob-close-btn:hover {
  color: rgba(124, 124, 124, 0.555);
  transition: 0.4s;
}

.station-connector-body {
  align-self: center;
  display: flex;
}
.station-body-buttons {
  display: flex;
}

.station-body-button {
  cursor: pointer;
  font-size: 15px;
  padding: 10px 10px;
  background-color: #ff3549;
  border-style: none;
  border-radius: 0;
  min-width: 130px;
  margin: 0 10px 10px;
}

.button-charge{
  transition: none;
  background-color: #41a350;
}

.button-reserve{
  transition: none;
  background-color: #C416FF;
}

.nope {
  text-transform: unset;
}

.counter-area {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px;
}

.input-bbbb {
  width: 40px;
  margin-right: 15px;
}

.reserve-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reserve-btn {
  background-color: #C416FF;
  margin: auto;
}

.reserve-tittle {
  text-align: center;
  margin: 5px;
  font-weight: 600;
  font-size: 24px;
}

.reserve-count {
  font-size: 48px;
  text-align: center;
  font-weight: 700;
}

.reserve-UAH {
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 24px;
}

.input-btn-area-increment {
  background-color: whitesmoke;
}
.input-btn-area-decrement {
  background-color: whitesmoke;
}
.input-btn-area-increment:hover {
  background-color: rgb(180, 180, 180);
}
.input-btn-area-decrement:hover {
  background-color: rgb(180, 180, 180);
}

.input-btn-area-increment:active {
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.75);
}
.input-btn-area-decrement:active {
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.connector-charge-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connector-hour-btn {
  margin: 10px 10px;
}

.connector-unlim-btn {
  margin: 10px 10px;
}

.connector-hour-message {
  position: relative;
  border: #41a350;
  color: #fff;
  background-color: #41a350;
  border-radius: 5px;
  padding: 20px;
}

.connector-hour-message-btn svg.close-btn {
  transition: 0.4s;
  width: 20px;
  height: 24px;
  cursor: pointer;
}

.close-btn:hover {
  transition: 0.2s;
  width: 12px;
  color: rgb(226, 223, 223);
  cursor: pointer;
}

.connector-hour-message-btn {
  position: absolute;
  right: 0;
  border: 0;
  background: 0;
  top: 1px;
}

.station-plug {
  display: flex;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background: #41a350;
  justify-content: center;
  border-radius: 7px;
}

.plug-text {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.connector-charge-btn_max-available {
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    padding: 10px 5px;
}

.current-balance {
    color: #fff;
    background-color: #41a350;
    border-radius: 7px;
    padding: 10px;
    margin: 10px;
}

@media (max-width: 1536px) {
  .station-title {
    font-size: 32px;
  }

  .station-connector-tittle {
    padding: 8px;
  }

  .station-connector {
    padding-bottom: 12px;
  }
}

@media (max-width: 626px){
  .connector-charge-btn_max-available {
    font-size: 14px;
  }
  .station-body{
    width: 85%;
  }
  .input-checkbox{
    width: 15px;
    height: 15px;
  }
  .connector-img {
    width: 38px;
    margin: 0 5px;
  }
  .station-connector-bold {
    font-size: 16px;
  }
  .station-connector-text {
    font-size: 14px;
  }
  .station-body-button{
    font-size: 12px;
    min-width: 126px;
  }
  .reserve-tittle, .reserve-UAH{
    font-size: 18px;
  }
  .reserve-count{
    font-size: 38px;
  }

}

@media (max-width: 450px) {
  .station-title {
    font-size: 26px;
  }
  .station-body {
    padding: 10px;
  }

  .station-info {
    padding-bottom: 10px;
  }

  .station-connector-bold {
    font-size: 14px;
  }
  .station-connector-prop {
    margin: 0 0 0 5px;
  }
  .station-connector-text {
    font-size: 12px;
  }
  .connector-img {
    width: 30px;
    margin: 0 5px;
  }
  .plug-text {
    font-size: 24px;
  }
}

@media (max-width: 374px) {
  .connector-img {
    width: 24px;
  }
}
