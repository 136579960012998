.wrapPhoneInput{
    text-align: center;
    width: 100px;
    margin-left: calc(50% - 182px);
}

.stations-table-body-btns {
    display: flex;
    justify-content: center;
}
.users-edit-block {
    display: flex;
    flex-direction: column;
}

.users-role-input {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.users-modal-body {
    background-color: #fff;
    border-radius: 10px;
    margin: 120px 0 0;
    padding: 25px;
    position: relative;
    z-index: 4;
}

.users-modal-markAndModel-email {
    display: flex;
    justify-content: space-around;
    text-align: center;

}

.users-saveBtn {
    cursor: pointer;
    margin: 10px 0;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px 13px;
}

.users-select {
    padding: 6px;
    font-size: 16px;
    width: 110px;
}

@media(max-width: 1536px) {
    .users-modal-body {
        margin: 90px 0 0;
    }
    
}

@media(max-width: 910px) {
    .stations-table-body-btns {
        margin: 0 0 20px;
    }
    .users-modal-body {
        margin: 80px 0 0 ;
    }
}

@media(max-width: 414px) {
    .users-block .stations-table{
        width: 100vw;
        display: block;
    }
    .users-block .stations-table-body{
        width: 100%;
        display: block;
    }
    .users-block .stations-table-body-block{
        max-width: 95%;
        margin: 0 auto 5px;
    }
}

@media(max-width: 414px) {
    .wrapPhoneInput{
        text-align: center;
        width: 310px;
        margin: 0 auto;
    }
}

option:hover {
    background-color: #136089;
}
