.afterRegBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 348px);
  margin: 110px 0 0;
}

.afterRegBlock-tittle {
  margin: 25px;
  font-size: 20px;
}

.afterRegBlock-text {
  margin: 20px;
  font-size: 16px;
}
.columns {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.button-reset {
  padding: 0;
  height: 2rem;
  width: 2rem;
}

.buttonimage {
  font-size: 25px;
}

.btns{
  display: flex;
  flex-direction: column;
}

.contract {
  color: black;
}

.contract a{
  color: rgb(44, 44, 252);
  cursor:pointer;
  text-decoration: underline;

}

.buttonimage:active {
  background-color: #fff;
}

.wrap-input {
  position: relative;
}

.showpassword-btn {
  cursor: pointer;
  top: 32px;
  position: absolute;
  right: 48px;
  border: none;
  background-color: unset;
  border-radius: 50%;
}

.showpassword-btn:hover {
  background-color: rgb(0, 0, 0);
  color: #fff;
}

.wrap-data-input {
  border: 2px solid black;
  display: flex;
  align-items: center;
  width: 320px;
  margin: 10px auto;
  padding: 10px;
  justify-content: space-between;
}
.login-form-title-link {
  margin: 0 10px 0 0;
}

.wrap-data-input-text {
  color: rgb(133, 133, 133);
  font-size: 16px;
  height: 45px;
  display: flex;
  align-items: center;
}

select {
  text-align: center;
  text-align-last: center;
  /* webkit*/
}

option {
  text-align: center;
  /* reset to left*/
}

.markAndModel-input-area {
  height: 40px;
  width: 200px;
  border: none;
  cursor: pointer;
}

.pass-fog-btn {
  cursor: pointer;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 0;
  min-width: 130px;
  margin: 10px 10px;
}

/* .react-date-picker  {
    border: 2px solid black;
} */

.react-date-picker__inputGroup {
  right: 64px;
  position: absolute;
}

.react-date-picker__button {
  padding: revert !important;
}

.react-date-picker__clear-button {
  right: 0;

  position: absolute;
}

.react-date-picker__calendar-button {
  position: absolute;
  right: 27px;
}
.react-date-picker__wrapper {
  border: none !important;
  align-items: center;
}

.react-date-picker__wrapper {
  background-color: rgba(255, 255, 255, 0.856) !important;
  margin: 10px !important;
  /* height: 45px!important; */
  padding: 10px !important;
  font-size: 16px !important;
  width: 140px !important;
  border: none;
}

@media (max-width: 1536px) {
  .afterRegBlock {
    min-height: calc(100vh - 320px);
    margin: 82px 0 0;
  }
}

@media (max-width: 640px) {
  .columns {
    flex-direction: column;
  }
  .showpassword-btn {
    right: 45px;
  }
  .react-tel-input .flag-dropdown {
    left: 12px;
  }
  .hide-mob {
    display: none;
  }
}

@media (max-width: 539px) {
  .afterRegBlock {
    padding-top: 71px;
    text-align: center;
  }
  .columns {
    flex-direction: column;
  }
  .react-tel-input .form-control {
    width: 225px !important;
  }
  .wrap-data-input {
    width: 270px;
    margin: auto;
  }
  .button-login {
    font-size: 20px;
  }

  .react-tel-input .flag-dropdown {
    left: 12px;
  }
}
