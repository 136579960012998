.edit_page{
    margin-top: 90px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.labels{
    width: 430px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.labels .input-area{
    width: auto!important;
}

.edit_page label{
    font-size: 22px;
    font-weight: bold;
    color: black;
    padding: 10px;
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit_page .label-phone{
    display: flex;
    justify-content: flex-end;
}

.edit_page input{
    margin: 10px;
}

@media (max-width: 440px){
    .edit_page label{
        font-size: 14px;
        padding: 0px;
    }

    .labels{
        width: 310px;
    }

    .labels .input-area{
        font-size: 12px !important;
    }
}