.users-block {
  position: relative;
  margin-top: 110px;
  min-height: calc(100vh - 348px);
}

.users-body {
  overflow: auto;
  width: 100%;
  margin: 10px 0 0 0;
}

.pagination-btn {
  cursor: pointer;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 13px;
}

.pagination-btn[disabled] {
  background: #d6d6d6;
}

.pagination-btn a {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}

.userPage-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.userPage-nums {
  display: flex;
  justify-content: center;
}

.userPage-num {
  margin: 0 5px;
  background: none;
  border: unset;
}

.picked {
  border-radius: 3px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: gray;
}

.userPage-input {
  width: 40px;
  margin-right: 5px;
  height: 20px;
}

@media (max-width: 1536px) {
  .users-block {
    margin: 82px 0 0;
    min-height: calc(100vh - 320px);
  }
}

@media (max-width: 768px) {
  .users-block {
    margin: 70px 0 0;
    min-height: calc(100vh - 712px);
  }
}
